import axios from "axios";
import api from "../server/api";

const GET_SHIPPING_TRACKING_REQUEST = "TYP/GET_SHIPPING_TRACKING_REQUEST";
const GET_SHIPPING_TRACKING_SUCCESS = "TYP/GET_SHIPPING_TRACKING_SUCCESS";
const GET_SHIPPING_TRACKING_FAILURE = "TYP/GET_SHIPPING_TRACKING_FAILURE";

const GET_SHIPPING_LOGO_REQUEST = "TYP/GET_SHIPPING_LOGO_REQUEST";
const GET_SHIPPING_LOGO_SUCCESS = "TYP/GET_SHIPPING_LOGO_SUCCESS";
const GET_SHIPPING_LOGO_FAILURE = "TYP/GET_SHIPPING_LOGO_FAILURE";

const GET_SHIPPING_BY_ID_REQUEST = "TYP/GET_SHIPPING_BY_ID_REQUEST";
const GET_SHIPPING_BY_ID_SUCCESS = "TYP/GET_SHIPPING_BY_ID_SUCCESS";
const GET_SHIPPING_BY_ID_FAILURE = "TYP/GET_SHIPPING_BY_ID_FAILURE";

const GET_USER_ALL_SHIPPINGS_REQUEST = "TYP/GET_USER_ALL_SHIPPINGS_REQUEST";
const GET_USER_ALL_SHIPPINGS_SUCCESS = "TYP/GET_USER_ALL_SHIPPINGS_SUCCESS";
const GET_USER_ALL_SHIPPINGS_FAILURE = "TYP/GET_USER_ALL_SHIPPINGS_FAILURE";

const SEND_TO_PUDO_REQUEST = "TYP/SEND_TO_PUDO_REQUEST";
const SEND_TO_PUDO_SUCCESS = "TYP/SEND_TO_PUDO_SUCCESS";
const SEND_TO_PUDO_FAILURE = "TYP/SEND_TO_PUDO_FAILURE";

const initialState = {
  loading: false,
  loadingLogo: false,
  error: false,
  data: null,
  shippingData: [],
  shippingLogo: null,
  shippingList: [],
  errorList: false,
  pudoSetted: false,
};

export default function reducerShippings(state = initialState, action) {
  switch (action.type) {
    case GET_SHIPPING_TRACKING_REQUEST:
      return { ...state, loading: true, error: false };
    case GET_SHIPPING_TRACKING_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_SHIPPING_TRACKING_FAILURE:
      return {
        ...state,
        loading: false,
        shippingLogo: null,
        error: action.payload,
      };
    case GET_SHIPPING_LOGO_REQUEST:
      return { ...state, loadingLogo: true, error: false };
    case GET_SHIPPING_LOGO_SUCCESS:
      return {
        ...state,
        loadingLogo: false,
        shippingLogo: action.payload,
      };
    case GET_SHIPPING_LOGO_FAILURE:
      return {
        ...state,
        loadingLogo: false,
        shippingLogo: null,
        // error: action.payload,
      };
    case GET_SHIPPING_BY_ID_REQUEST:
      return { ...state, loading: true, error: false };
    case GET_SHIPPING_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        shippingData: action.payload,
      };
    case GET_SHIPPING_BY_ID_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };
    case GET_USER_ALL_SHIPPINGS_REQUEST:
      return { ...state, loading: true, errorList: false };
    case GET_USER_ALL_SHIPPINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        shippingList: action.payload,
        errorList: false,
      };
    case GET_USER_ALL_SHIPPINGS_FAILURE:
      return {
        ...state,
        loading: false,
        shippingList: [],
        errorList: action.payload,
      };
    case SEND_TO_PUDO_REQUEST:
      return { ...state, loading: true, error: false };
    case SEND_TO_PUDO_SUCCESS:
      return {
        ...state,
        loading: false,
        pudoSetted: action.payload,
      };
    case SEND_TO_PUDO_FAILURE:
      return {
        ...state,
        loading: false,
        pudoSetted: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export const getTrackingData = (id, key, language) => {
  return async (dispatch) => {
    dispatch({
      type: GET_SHIPPING_TRACKING_REQUEST,
    });
    try {
      const { url, method, headers, params } = api.getTrackingData(
        id,
        key,
        language
      );
      const shippingData = await axios({
        url,
        method,
        headers,
        params,
      });
      dispatch({
        type: GET_SHIPPING_TRACKING_SUCCESS,
        payload: shippingData.data,
      });
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);
      dispatch({
        type: GET_SHIPPING_TRACKING_FAILURE,
        payload: error,
      });
    }
  };
};

export const getTrackingDataWithCode = (reference, contractCode, language) => {
  return async (dispatch) => {
    dispatch({
      type: GET_SHIPPING_TRACKING_REQUEST,
    });
    try {
      const { url, method, headers, params } = api.getTrackingDataWithCode(
        reference,
        contractCode,
        language
      );
      const shippingData = await axios({
        url,
        method,
        headers,
        params,
      });
      localStorage.setItem("id", shippingData.data.shipmentId);
      dispatch({
        type: GET_SHIPPING_TRACKING_SUCCESS,
        payload: shippingData.data,
      });
    } catch (error) {
      console.log("error", error);
      console.log("error response", error.response);
      dispatch({
        type: GET_SHIPPING_TRACKING_FAILURE,
        payload: error,
      });
    }
  };
};

export const getTrackingDataWithCustomerCode = (
  customerCode,
  customerReference,
  language
) => {
  return async (dispatch) => {
    dispatch({
      type: GET_SHIPPING_TRACKING_REQUEST,
    });
    try {
      const { url, method, headers, params } =
        api.getTrackingDataWithCustomerCode(
          customerCode,
          customerReference,
          language
        );

      const shippingData = await axios({
        url,
        method,
        headers,
        params,
      });
      localStorage.setItem("id", shippingData.data.shipmentId);
      dispatch({
        type: GET_SHIPPING_TRACKING_SUCCESS,
        payload: shippingData.data,
      });
    } catch (error) {
      console.log("error", error);
      console.log("error response", error.response);
      dispatch({
        type: GET_SHIPPING_TRACKING_FAILURE,
        payload: error,
      });
    }
  };
};

export const getShippingLogo = (contractId) => {
  return async (dispatch) => {
    dispatch({
      type: GET_SHIPPING_LOGO_REQUEST,
    });
    try {
      const { url, method, headers } = api.getShippingLogo(contractId);
      const shippingLogo = await axios({
        url,
        method,
        headers,
      });
      dispatch({
        type: GET_SHIPPING_LOGO_SUCCESS,
        payload: shippingLogo.data,
      });
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);
      dispatch({
        type: GET_SHIPPING_LOGO_FAILURE,
        payload: error,
      });
    }
  };
};

export const getShippingById = (id, key) => {
  return async (dispatch) => {
    dispatch({
      type: GET_SHIPPING_BY_ID_REQUEST,
    });
    try {
      const { url, method, headers, params, data } = api.getShippingById(
        id,
        key
      );
      const shippingData = await axios({
        url,
        method,
        headers,
        params,
        data,
      });

      dispatch({
        type: GET_SHIPPING_BY_ID_SUCCESS,
        payload: shippingData.data,
      });
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);
      dispatch({
        type: GET_SHIPPING_BY_ID_FAILURE,
        payload: error,
      });
    }
  };
};

export const getAllShippings = (otpCode) => {
  return async (dispatch) => {
    dispatch({
      type: GET_USER_ALL_SHIPPINGS_REQUEST,
    });
    try {
      const { url, method, headers, data } = api.getAllShippings(otpCode);
      const shippings = await axios({
        url,
        method,
        headers,
        data,
      });

      dispatch({
        type: GET_USER_ALL_SHIPPINGS_SUCCESS,
        payload: shippings.data,
      });
      return shippings.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response.data.message);
      dispatch({
        type: GET_USER_ALL_SHIPPINGS_FAILURE,
        payload: error.response.data.message,
      });
    }
  };
};
export const sendShippingToPudo = (shippingId, pudo, key) => {
  return async (dispatch) => {
    dispatch({
      type: SEND_TO_PUDO_REQUEST,
    });
    try {
      const { url, method, headers, data } = api.sendToPudo(
        shippingId,
        pudo.id,
        key
      );
      const shippings = await axios({
        url,
        method,
        headers,
        data,
      });

      /*    dispatch({
        type: SEND_TO_PUDO_SUCCESS,
        payload: shippings.data,
      }); */
      dispatch({
        type: SEND_TO_PUDO_SUCCESS,
        payload: pudo,
      });
      return shippings.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response.data.message);
      dispatch({
        type: SEND_TO_PUDO_FAILURE,
        payload: error.response.data.message,
      });
    }
  };
};
