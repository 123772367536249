import { useEffect, useState } from "react";
import { ReactComponent as RegistrataBlue } from "../../assets/icons/registrataBlu.svg";
import { ReactComponent as RitirataBlue } from "../../assets/icons/ritirataBlu.svg";
import { ReactComponent as ConsegnataBlue } from "../../assets/icons/consegnataBlu.svg";
import { ReactComponent as InConsegnaBlue } from "../../assets/icons/inConsegnaBlu.svg";
import { ReactComponent as InTransitoBlue } from "../../assets/icons/inTransitoBlu.svg";
import { ReactComponent as ExclamationPoint } from "../../assets/icons/exclamation-point.svg";
import { ReactComponent as Registrata } from "../../assets/icons/registrata.svg";
import { ReactComponent as Ritirata } from "../../assets/icons/ritirata.svg";
import { ReactComponent as Consegnata } from "../../assets/icons/consegnata.svg";
import { ReactComponent as InConsegna } from "../../assets/icons/inConsegna.svg";
import { ReactComponent as InTransito } from "../../assets/icons/inTransito.svg";
import { ReactComponent as Check } from "../../assets/icons/checkBlue.svg";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { VerticalLineTrack } from "../../shared/CheckCircle";

import moment from "moment";
import { Text, translate } from "../../assets/lemmata";
import "./TrackingSection.scss";
import TrackingLine from "./TrackingLine";
import {
  getReachedStep,
  // isLastEventError,
  // isLastEventWarning,
  isSectionError,
  isSectionWarning,
} from "./FormatEventsFunc";
import { Popup } from "../Popups/Popup";

const TrackingSection = (props) => {
  const [trackingSection, setTrackingSection] = useState(0);

  const trackingSections = [
    {
      id: 0,
      icon: <RegistrataBlue width={28} height={28} />,
      iconWhite: (
        <Registrata
          style={{ stroke: "white", fill: "white" }}
          width={28}
          height={28}
        />
      ),
      title: <Text label="inPreparazione" />,
    },
    {
      id: 1,
      icon: <RitirataBlue width={28} height={28} />,
      iconWhite: (
        <Ritirata
          style={{ stroke: "white", fill: "white" }}
          width={28}
          height={28}
        />
      ),
      title: <Text label="ritirata" />,
    },
    {
      id: 2,
      icon: <InTransitoBlue width={28} height={28} />,
      iconWhite: (
        <InTransito
          style={{ stroke: "white", fill: "white" }}
          width={28}
          height={28}
        />
      ),
      title: <Text label="inTransito" />,
    },
    {
      id: 3,
      icon: <InConsegnaBlue width={28} height={28} />,
      iconWhite: (
        <InConsegna
          style={{ stroke: "white", fill: "white" }}
          width={28}
          height={28}
        />
      ),
      title: (
        <Text
          label={props.provisionId === 1 ? "rientroAlMittente" : "inConsegna"}
        />
      ),
      titleToggle: (
        <Text
          label={
            props.provisionId === 1 ? "rientroAlMittente" : "ordineInConsegna"
          }
        />
      ),
      titlePudo: (
        <Text
          label={
            props.provisionId === 1 ? "rientroAlMittente" : "disponibileRitiro"
          }
        />
      ),
    },
    {
      id: 4,
      icon: <ConsegnataBlue width={26} height={26} />,
      iconWhite: (
        <Consegnata
          style={{ stroke: "white", fill: "white" }}
          width={26}
          height={26}
        />
      ),
      title: (
        <Text
          label={
            props.provisionId === 1 ? "consegnatoAlMittente" : "consegnata"
          }
        />
      ),
      titleToggle: (
        <Text
          label={
            props.provisionId === 1
              ? "consegnatoAlMittente"
              : "ordineConsegnato"
          }
        />
      ),
      titlePudo: (
        <Text
          label={
            props.provisionId === 1 ? "consegnatoAlMittente" : "ordineRitirato"
          }
        />
      ),
    },
  ];
  // check if it is a return

  const isReturnShipment = props.serviceCode === "R";

  return (
    <div className="tracking-container">
      <TrackingLine
        trackingSections={trackingSections.filter((section) => {
          // if it is a return shipment, hide the "inTransit" section
          if (!!isReturnShipment) {
            return section.id !== 3;
          } else return section;
        })}
        trackingEvents={props.trackingEvents}
        provisionId={props.provisionId}
      />
      <div>
        {trackingSections
          .filter((section) => {
            // if it is a return shipment, hide the "inTransit" section
            if (!!isReturnShipment) {
              return section.id !== 3;
            } else return section;
          })
          .map((section) => {
            const sectionEvents = props.trackingEvents.filter(
              (event) => event.stepCode === section.id
            );
            return (
              <TrackingToggle
                trackingSection={trackingSection}
                key={`id_${section.id}`}
                section={section}
                sectionEvents={sectionEvents}
                trackingEvents={props.trackingEvents}
                storageId={props.storageId}
                provisionId={props.provisionId}
                provisionLabel={props.provisionLabel}
                getKoReason={props.getKoReason}
                language={props.language}
              />
            );
          })}
      </div>
    </div>
  );
};

export default TrackingSection;

const TrackingToggle = (props) => {
  // const isError = isLastEventError(props.trackingEvents);
  // const isWarning = isLastEventWarning(props.trackingEvents);
  const isLastEventOK =
    props.trackingEvents[props.trackingEvents.length - 1].status === "ok";
  const isError = isSectionError(props.trackingEvents, props.section.id);
  const isWarning = isSectionWarning(props.trackingEvents, props.section.id);
  const reachedStep = getReachedStep(props.trackingEvents);
  const isChecked = props.section.id <= reachedStep;
  const [isToggleOpen, setIsToggleOpen] = useState(false);
  const lastEvent =
    props.sectionEvents.length > 0 &&
    props.sectionEvents[props.sectionEvents.length - 1];
  const isReturnToSender = props.provisionId === 1;

  return (
    <div
      className={`tracking-toggle ${
        ((props.sectionEvents.length <= 0 && !isChecked) ||
          (isWarning && props.provisionId && !isReturnToSender)) &&
        "disabled"
      }`}
      onClick={() => setIsToggleOpen(!isToggleOpen)}
    >
      <div className="section-row">
        <div style={{ fill: "var(--toggleSectionIconColor)" }}>
          {props.section.icon}
        </div>
        <div className="tracking-toggle-title" style={{ flex: 5 }}>
          {props.sectionEvents.find(
            (event) => event.eventCode === 82 || event.eventCode === 83
          ) ? (
            <>
              {
                // props.provisionLabel && props.section.id == reachedStep + 1
                //   ? `${props.provisionLabel}`
                //   :
                props.section.titlePudo || props.section.title
              }
            </>
          ) : (
            <>
              {
                // props.provisionLabel && props.section.id == reachedStep + 1
                //   ? `${props.provisionLabel}`
                //   :
                props.section.titleToggle || props.section.title
              }
            </>
          )}
          {!!lastEvent && (
            <div className="tracking-toggle-subtitle">
              {/* {`${moment(
              lastEvent.dateTime
            ).format("DD/MM/YY - HH:mm")} - ${lastEvent.eventName} ${
              lastEvent.warehouseName ? "- " + lastEvent.warehouseName : ""
            }`} */}

              {!!props.storageId && lastEvent.eventCode === 35 ? (
                <>
                  {props.provisionId ? (
                    <div>{props.provisionLabel}</div>
                  ) : (
                    <div>
                      <Text label="disposizioniNonRicevute" />
                    </div>
                  )}
                </>
              ) : lastEvent.eventCode === 11 ? (
                <div>{`${moment(lastEvent.dateTime).format(
                  "DD/MM/YY - HH:mm"
                )} - ${lastEvent.eventName} - ${translate(
                  "motivo",
                  props.language
                )}:  ${props.getKoReason(lastEvent.eventId)}`}</div>
              ) : (
                /*  <div>
                  {`${moment(lastEvent.dateTime).format("DD/MM/YY - HH:mm")} 
                  ${
                    lastEvent.warehouseName
                      ? "- " + lastEvent.warehouseName
                      : ""
                  }`
                  }{" "}
                </div> */
                <div>
                  {`${moment(lastEvent.dateTime).format("DD/MM/YY - HH:mm")} 
                `}{" "}
                </div>
              )}
            </div>
          )}
        </div>
        {props.sectionEvents.length > 0 || isChecked ? (
          <div
            className="tracking-toggle-dot"
            style={{
              backgroundColor:
                isLastEventOK ||
                (isChecked && !isWarning && !isError) ||
                (isChecked && !isLastEventOK && isReturnToSender)
                  ? "var(--secondary)"
                  : isWarning
                  ? "var(--warning)"
                  : isError
                  ? "var(--danger)"
                  : "black",
              // isChecked ? "var(--secondary)" : isError ? 'var(--danger)' : "var(--warning)",
            }}
          >
            {isLastEventOK ||
            (isChecked && !isWarning && !isError) ||
            (isChecked && !isLastEventOK && isReturnToSender) ? (
              <Check width={20} height={20} />
            ) : (
              <ExclamationPoint
                width={32}
                height={32}
                style={{ fill: "white" }}
              />
            )}
          </div>
        ) : (
          <div style={{ width: 32 }}></div>
        )}
      </div>
      {isToggleOpen && props.sectionEvents.length > 0 && (
        <Popup>
          <div style={{ maxHeight: "70vh" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
                position: "absolute",
                top: "16px",
                right: "16px",
                overflow: "auto",
              }}
            >
              <Close
                onClick={() => setIsToggleOpen(!isToggleOpen)}
                width={24}
                height={24}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // paddingTop: "5%",
              }}
            >
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  // justifyContent: "center",
                }}
              >
                {props.sectionEvents.length > 0 || isChecked ? (
                  <div
                    className="tracking-toggle-dot"
                    style={{
                      // backgroundColor:
                      // isChecked
                      //   ? "var(--secondary)"
                      //   : isError
                      //   ? 'var(--danger)'
                      //   : "var(--warning)",
                      backgroundColor: isLastEventOK
                        ? "var(--secondary)"
                        : isWarning
                        ? "var(--warning)"
                        : isError
                        ? "var(--danger)"
                        : isChecked
                        ? "var(--secondary)"
                        : "black",
                    }}
                  >
                    {isLastEventOK || (isChecked && !isWarning && !isError) ? (
                      <Check width={20} height={20} />
                    ) : (
                      <ExclamationPoint
                        width={32}
                        height={32}
                        style={{ fill: "white" }}
                      />
                    )}
                  </div>
                ) : (
                  <div style={{ width: 32 }}></div>
                )}
              </div>
              <div className="tracking-toggle-title-popup">
                {
                  // props.provisionLabel && props.section.id == reachedStep + 1
                  //   ? `${props.provisionLabel}`
                  //   :
                  props.section.titleToggle || props.section.title
                }
              </div>
              <div style={{ flex: 1 }}></div>
            </div>
            <div className="toggle-section">
              <div
                className="section-row"
                style={{ fontWeight: 600, borderBottom: "1px solid #e3e3e3" }}
              >
                <Text label="data" />
                <Text label="orario" />
                <Text label="status" />
              </div>
              {props.sectionEvents.map((event, i) => {
                return (
                  <div key={`event_${event.dateTime}`} className="section-row">
                    <div>{moment(event.dateTime).format("DD/MM/yyyy")}</div>
                    <div>{moment(event.dateTime).format("HH:mm")}</div>
                    <div>
                      {/* {event.eventName} <br /> {event.warehouseName} */}
                      {!!props.storageId && event.eventCode === 35 ? (
                        <>
                          {props.provisionId ? (
                            <div>{props.provisionLabel}</div>
                          ) : (
                            <div>
                              <Text label="disposizioniNonRicevute" />
                            </div>
                          )}
                        </>
                      ) : event.eventCode === 11 ? (
                        <div>
                          {event.eventName} <br />{" "}
                          {translate("motivo", props.language)}
                          {": "}
                          {props.getKoReason(event.eventId)} <br />
                          {event.warehouseName}
                        </div>
                      ) : (
                        <div>
                          {event.eventName} <br /> {event.warehouseName}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="toggle-section-mobile">
              {props.sectionEvents.map((event, i) => {
                return (
                  <div
                    key={`event_${event.dateTime}`}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ padding: "4px 8px", textAlign: "center" }}>
                      <div className="toggle-section-date-mobile">
                        {moment(event.dateTime).format("DD/MM/yyyy")}{" "}
                        {moment(event.dateTime).format("HH:mm")}
                      </div>

                      <div className="toggle-section-label-mobile">
                        {/* {event.eventName} <br /> {event.warehouseName} */}
                        {!!props.storageId && event.eventCode === 35 ? (
                          <>
                            {props.provisionId ? (
                              <div>{props.provisionLabel}</div>
                            ) : (
                              <div>
                                <Text label="disposizioniNonRicevute" />
                              </div>
                            )}
                          </>
                        ) : event.eventCode === 11 ? (
                          <div>
                            <div style={{ fontWeight: "normal" }}>
                              {event.eventName}
                            </div>
                            <div style={{ fontWeight: "normal" }}>
                              {translate("motivo", props.language)}
                              {": "}
                              {props.getKoReason(event.eventId)}
                            </div>
                            {event.warehouseName && (
                              <div className="toggle-section-mobile-warehouse">
                                {event.warehouseName}
                              </div>
                            )}
                          </div>
                        ) : (
                          <>
                            <div style={{ fontWeight: "normal" }}>
                              {event.eventName}
                            </div>
                            {event.warehouseName && (
                              <div className="toggle-section-mobile-warehouse">
                                {event.warehouseName}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    {i !== props.sectionEvents.length - 1 && (
                      <div
                        style={{
                          height: 24,
                          position: "relative",
                        }}
                      >
                        <VerticalLineTrack />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </Popup>
      )}
    </div>
  );
};

/////// versione con tracking

/////// versione solo con data e label

// {props.trackingSection % 4 === 0 ? (
//   <div className="toggle-section-mobile">
//     {props.sectionEvents.map((event, i) => {
//       return (
//         <div
//           key={`event_${event.dateTime}`}
//           style={{
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "center",
//           }}
//         >
//           <div style={{ padding: "4px 8px", textAlign: "center" }}>
//             <div className="toggle-section-date-mobile">
//               {moment(event.dateTime).format("DD/MM/yyyy")}{" "}
//               {moment(event.dateTime).format("HH:mm")}
//             </div>

//             <div className="toggle-section-label-mobile">
//               {/* {event.eventName} <br /> {event.warehouseName} */}
//               {!!props.storageId && event.eventCode === 35 ? (
//                 <>
//                   {props.provisionId ? (
//                     <div>{props.provisionLabel}</div>
//                   ) : (
//                     <div>
//                       <Text label="disposizioniNonRicevute" />
//                     </div>
//                   )}
//                 </>
//               ) : (
//                 <>
//                   <div style={{ fontWeight: "normal" }}>
//                     {event.eventName}
//                   </div>
//                   {event.warehouseName && (
//                     <div
//                       style={{
//                         fontSize: "0.7rem",
//                         fontWeight: "normal",
//                       }}
//                     >
//                       {event.warehouseName}
//                     </div>
//                   )}
//                 </>
//               )}
//             </div>
//           </div>
//           {i !== props.sectionEvents.length - 1 && (
//             <div
//               style={{
//                 height: 24,
//                 position: "relative",
//               }}
//             >
//               <VerticalLineTrack />
//             </div>
//           )}
//         </div>
//       );
//     })}
//   </div>
// ) : props.trackingSection % 4 === 1 ? (
//   <div className="toggle-section-mobile">
//     {props.sectionEvents.map((event, i) => {
//       return (
//         <div
//           key={`event_${event.dateTime}`}
//           style={{ display: "flex" }}
//         >
//           <LightCheckCircle />
//           <div style={{ padding: "4px 8px" }}>
//             <div className="toggle-section-date-mobile">
//               {moment(event.dateTime).format("DD/MM/yyyy")}{" "}
//               {moment(event.dateTime).format("HH:mm")}
//             </div>
//             <div className="toggle-section-label-mobile">
//               {/* {event.eventName} <br /> {event.warehouseName} */}
//               {!!props.storageId && event.eventCode === 35 ? (
//                 <>
//                   {props.provisionId ? (
//                     <div>{props.provisionLabel}</div>
//                   ) : (
//                     <div>
//                       <Text label="disposizioniNonRicevute" />
//                     </div>
//                   )}
//                 </>
//               ) : (
//                 <div>
//                   {event.eventName}{" "}
//                   {event.warehouseName &&
//                     `- ${event.warehouseName}`}
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//       );
//     })}
//   </div>
// ) : props.trackingSection % 4 === 2 ? (
//   <div className="toggle-section-mobile">
//     {props.sectionEvents.map((event, i) => {
//       return (
//         <div key={`event_${event.dateTime}`}>
//           <div style={{ padding: "4px 8px" }}>
//             <div className="toggle-section-date-mobile">
//               {moment(event.dateTime).format("DD/MM/yyyy")}{" "}
//               {moment(event.dateTime).format("HH:mm")}
//             </div>
//             <div className="toggle-section-label-mobile">
//               {/* {event.eventName} <br /> {event.warehouseName} */}
//               {!!props.storageId && event.eventCode === 35 ? (
//                 <>
//                   {props.provisionId ? (
//                     <div>{props.provisionLabel}</div>
//                   ) : (
//                     <div>
//                       <Text label="disposizioniNonRicevute" />
//                     </div>
//                   )}
//                 </>
//               ) : (
//                 <div>
//                   {event.eventName}{" "}
//                   {event.warehouseName &&
//                     `- ${event.warehouseName}`}
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//       );
//     })}
//   </div>
// ) : (
//   <div className="toggle-section-prov">
//     <div
//       className="section-row"
//       style={{ fontWeight: 600, borderBottom: "1px solid #e3e3e3" }}
//     >
//       <Text label="data" />
//       <Text label="orario" />
//       <Text label="status" />
//     </div>
//     {props.sectionEvents.map((event, i) => {
//       return (
//         <div
//           key={`event_${event.dateTime}`}
//           className="section-row"
//         >
//           <div>{moment(event.dateTime).format("DD/MM/yyyy")}</div>
//           <div>{moment(event.dateTime).format("HH:mm")}</div>
//           <div>
//             {/* {event.eventName} <br /> {event.warehouseName} */}
//             {!!props.storageId && event.eventCode === 35 ? (
//               <>
//                 {props.provisionId ? (
//                   <div>{props.provisionLabel}</div>
//                 ) : (
//                   <div>
//                     <Text label="disposizioniNonRicevute" />
//                   </div>
//                 )}
//               </>
//             ) : (
//               <div>
//                 {event.eventName} <br /> {event.warehouseName}
//               </div>
//             )}
//           </div>
//         </div>
//       );
//     })}
//   </div>
// )}
